import './styles.css';
import {Col, Layout, Row} from "antd";
import {MapPin, Phone, Mail} from "react-feather";

const ContactUS = () => {

    return <Layout className={'footer'} id={'contact'} style={{marginTop: 90}}>


        <Row gutter={[8]}>
            <Col xs={24} md={12} span={12}>

                <iframe width="100%" height="600" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Boulevard%20Cheikh%20Zaid%20Lac%20Tunis+(Shinra)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                    <a href="https://www.gps.ie/marine-gps/">shipping gps</a></iframe>

            </Col>
            <Col xs={24} md={12} className={'footer_section'} span={12}>
                <div className={'footer_section_body'}>
                    <h1>Contactez-Nous</h1>
                    <ul>
                        <li><Mail style={{marginRight: 20}} size={50} color={'#ff2265'}/> <a
                            href={"mailto:gestion.shinra@gmail.com"}> gestion.shinra@gmail.com</a></li>
                        <li><Phone style={{marginRight: 20}} size={50} color={'#ff2265'}/> <a
                            href={"tel:+216 51 134 732"}>51 134 732</a></li>
                        <li><MapPin style={{marginRight: 20}} size={50} color={'#ff2265'}/><a
                            href="'javascript:void(0)'"> Boulevard Cheikh Zaid Lac
                            1053 Tunis</a>
                        </li>
                    </ul>
                </div>
            </Col>
        </Row>
    </Layout>

}

export default ContactUS;